import * as React from 'react';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { ChevronLeft } from '@styled-icons/material-outlined/ChevronLeft';
import { Circle } from '@styled-icons/material-rounded/Circle';
import { useSessionUser } from '@/hooks/useSessionUser';
import { useRoomStore } from '@/hooks/stores/useRoomStore';
import Loading from '@/components/Loading/Loading';
import Link from 'next/link';
import { useAudio } from '@/context/AudioContext';
import SettingModal from '@/components/_modals/SettingModal';
import { Tooltip } from 'react-tooltip';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';

const text = 'isThereAmerica';

const touristTextLists = [
  'isThereAmerica',
  'isThereAfrica',
  'isThereAustralia',
  'isWorldHeritage',
  'isOldBuilding',
  'isFamousForLake',
];

const aiTexts = ['manyPeopleVisitThere', 'noItsNot.'];

export default function Page() {
  const { user, reports, isCompleted, getUserReports } = useSessionUser();
  const router = useRouter();
  const { t, i18n } = useTranslation();

  const { playSoundEffect } = useAudio();
  const { roomId: storedRoomId, setRoomId } = useRoomStore();
  const [hasLobby, setHasLobby] = React.useState(false);
  const [isDisplaySettingModal, setIsDisplaySettingModal] =
    React.useState(false);
  const [displayedText, setDisplayedText] = React.useState('');
  const [displayedAIText, setDisplayedAIText] = React.useState('');
  const [displayRoomMenu, setDisplayRoomMenu] = React.useState(false);
  const [displayOriginalQuestionMenu, setDisplayOriginalQuestionMenu] =
    React.useState(false);

  const textRef = React.useRef<string>();
  textRef.current = displayedText;

  const handleNavigateToPreviousRoom = () => {
    handleClick();
    router.push(`/rooms/${storedRoomId}`);
  };

  const handleOpenSettingModal = () => {
    setIsDisplaySettingModal(true);
    handleClick();
  };

  const handleCloseSettingModal = () => {
    setIsDisplaySettingModal(false);
  };

  const handleClick = () => {
    playSoundEffect('click_button');
  };

  const handleDisplayRoomMenu = () => {
    setDisplayRoomMenu(true);
    setDisplayOriginalQuestionMenu(false);
  };

  const handleCloseMenu = () => {
    setDisplayRoomMenu(false);
    setDisplayOriginalQuestionMenu(false);
  };

  const handleDisplayOriginalQuestionMenu = () => {
    setDisplayOriginalQuestionMenu(true);
    setDisplayRoomMenu(false);
  };

  const quitApp = () => {
    window.electronAPI.quitApp();
  };

  const handleClickDiscord = React.useCallback(() => {
    handleClick();

    window.electronAPI.openExternal(t('discordLink'));
  }, []);

  React.useEffect(() => {
    let index = 0;
    let intervalId: NodeJS.Timeout;

    const getRandomTextFromArray = (array: string[]) => {
      const randomIndex = Math.floor(Math.random() * array.length);
      return array[randomIndex];
    };

    const getReports = async () => {
      await getUserReports(undefined, true);
    };

    const displayText = () => {
      index = 0;
      window.document.title = t('title');
      const randomText = getRandomTextFromArray(touristTextLists);
      const randomAIText = getRandomTextFromArray(aiTexts);

      const clear = () => {
        if (intervalId) {
          clearInterval(intervalId);
        }
        setTimeout(() => {
          setDisplayedText('');
          setDisplayedAIText('');
          displayText();
        }, 4000); // 8秒後に再度表示
      };

      intervalId = setInterval(() => {
        if (index === text.length || !randomText[index]) {
          clear();
          setDisplayedAIText(randomAIText);
          return;
        }
        if (textRef.current === undefined) return;
        setDisplayedText(textRef.current + randomText[index]);
        index = index + 1;
      }, 200); // 0.5秒おきに文字を追加
    };

    displayText();

    const navigateToRoom = async (event: any, data: any) => {
      const roomId = await window.electronAPI.getRoomId();
      router.push('/');
      function toRoom() {
        router.push(`/rooms/${roomId}`);
        setTimeout(() => {
          setHasLobby(false);
        }, 1000);
      }
      if (roomId) {
        setHasLobby(true);
        setTimeout(() => {
          toRoom();
        }, 1500);
      }
    };

    window.electronAPI.on('join-lobby', navigateToRoom);
    getReports();
    return () => {
      window.electronAPI.off('join-lobby', navigateToRoom);
      if (!intervalId) return;
      clearInterval(intervalId);
    };
  }, []);

  return (
    <>
      {isCompleted && (
        <>
          {user ? (
            <>
              {hasLobby ? (
                <Loading title={t('joiningRoom')} size={8} />
              ) : (
                <div className="relative flex justify-center items-center gap-20 h-full">
                  {storedRoomId && (
                    <div
                      className="absolute top-6 left-6 flex items-center gap-2 text-brown-primary text-2xl cursor-pointer"
                      onClick={handleNavigateToPreviousRoom}
                    >
                      <ChevronLeft size={32} /> {t('backToRoom')}
                    </div>
                  )}
                  <div
                    className="absolute bottom-4 left-4 room-icon flex hover-scale-1_3 items-center gap-2 justify-end text-xl  text-brown-secondary font-bold cursor-pointer active-scale-90"
                    style={{ letterSpacing: '2px' }}
                    onClick={handleClickDiscord}
                  >
                    <img
                      src="/images/icon_discord.png"
                      alt="to discord server"
                    />
                    {t('discord')}
                  </div>
                  <div className="relative">
                    <img
                      src={`/images/title_logo_${i18n.language}.png`}
                      alt="logo"
                      className="min-w-[500px] h-[500px]"
                    />
                    {/* <div className="absolute top-[224px] right-[5px]">
                      {t(`${displayedText}`)
                        .split('')
                        .map((char, i) => (
                          <span
                            key={i}
                            className=".character text-white"
                            style={{ animationDelay: `${i * 1}s` }}
                          >
                            {char}
                          </span>
                        ))}
                    </div> */}
                    {/* <div className="absolute text-sm w-[100px] h-[54px] top-[118px] right-[96px] text-white">
                      {t(`${displayedAIText}`)}
                    </div> */}
                  </div>
                  <div className="relative flex flex-col gap-10 opacity-90 text-brown-primary">
                    {displayRoomMenu && (
                      <div className="absolute -right-[270px] flex flex-col gap-[40px]">
                        <Link
                          href={'/create-room'}
                          className='relative select-none bg-[url("/images/menu_line.png")] w-[300px] h-[20px] block'
                          style={{ backgroundSize: '100% 100%' }}
                          onClick={handleClick}
                        >
                          <div className="absolute -top-8 text-3xl left-[10%] span-gradient span-gradient-hover">
                            {t('createRoom.title')}
                          </div>
                        </Link>
                        <Link
                          href={'/join'}
                          className='relative select-none bg-[url("/images/menu_line.png")] w-[300px] h-[20px] ml-[40px] block'
                          style={{ backgroundSize: '100% 100%' }}
                          onClick={handleClick}
                        >
                          <div className="absolute -top-8 text-3xl left-[10%] span-gradient span-gradient-hover">
                            {t('joinRoomWithId.title')}
                          </div>
                        </Link>
                        {i18n.language === 'jp' && (
                          <Link
                            href={'/lobbies'}
                            className='relative select-none bg-[url("/images/menu_line.png")] w-[300px] h-[20px] ml-[80px] block'
                            style={{ backgroundSize: '100% 100%' }}
                            onClick={handleClick}
                          >
                            <div className="absolute -top-8 text-3xl left-[10%] span-gradient span-gradient-hover">
                              {t('findRoom.title')}
                            </div>
                          </Link>
                        )}
                      </div>
                    )}

                    <div
                      className='relative select-none bg-[url("/images/menu_line.png")] w-[300px] h-[20px] ml-[80px]'
                      style={{ backgroundSize: '100% 100%' }}
                      onMouseEnter={handleDisplayRoomMenu}
                    >
                      <div className="absolute cursor-pointer -top-8 text-3xl left-[10%] span-gradient span-gradient-hover">
                        {t('play')}
                      </div>
                    </div>
                    {displayOriginalQuestionMenu && (
                      <div className="absolute -right-[270px] top-[60px] flex flex-col gap-[40px]">
                        <Link
                          href={'/original-questions'}
                          className='relative select-none bg-[url("/images/menu_line.png")] w-[300px] h-[20px] block'
                          style={{ backgroundSize: '100% 100%' }}
                          onClick={handleClick}
                        >
                          <div className="absolute -top-8 text-3xl left-[10%] span-gradient span-gradient-hover">
                            問題を作る
                          </div>
                        </Link>
                        <Link
                          href={'/reports'}
                          className='relative select-none bg-[url("/images/menu_line.png")] w-[300px] h-[20px] ml-[40px] block'
                          style={{ backgroundSize: '100% 100%' }}
                          onClick={handleClick}
                        >
                          <div className="absolute -top-8 text-3xl left-[10%] span-gradient span-gradient-hover">
                            <div className="flex items-center gap-2">
                              間違い報告
                              {reports.length > 0 && (
                                <div className="absolute -top-6 -right-6">
                                  <Circle
                                    className="opacity-90"
                                    color="red"
                                    size={12}
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        </Link>
                      </div>
                    )}
                    <div
                      className='relative select-none bg-[url("/images/menu_line.png")] w-[300px] h-[20px] ml-[120px]'
                      style={{ backgroundSize: '100% 100%' }}
                      onMouseEnter={handleDisplayOriginalQuestionMenu}
                      onClick={handleClick}
                    >
                      <div className="absolute cursor-pointer -top-8 text-3xl left-[10%]">
                        <div className="relative flex items-center gap-2  span-gradient span-gradient-hover">
                          オリジナル問題
                          {reports.length > 0 && (
                            <div className="absolute -top-6 -right-6">
                              <Circle
                                className="opacity-90"
                                color="red"
                                size={12}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <Tooltip id="report-tooltip" />
                    <button
                      className='relative select-none bg-[url("/images/menu_line.png")] w-[300px] h-[20px] ml-[160px]'
                      style={{ backgroundSize: '100% 100%' }}
                      onMouseEnter={handleCloseMenu}
                      onClick={handleOpenSettingModal}
                    >
                      <div className="absolute -top-8 text-3xl left-[5%] span-gradient span-gradient-hover">
                        {t('options.title')}
                      </div>
                    </button>
                    <button
                      className='relative bg-[url("/images/menu_line.png")] w-[300px] h-[20px] ml-[200px]'
                      style={{ backgroundSize: '100% 100%' }}
                      onMouseEnter={handleCloseMenu}
                      onClick={quitApp}
                    >
                      <div className="absolute -top-8 text-3xl left-[5%] span-gradient span-gradient-hover">
                        {t('quit')}
                      </div>
                    </button>
                  </div>
                  {isDisplaySettingModal && (
                    <SettingModal onClose={handleCloseSettingModal} />
                  )}
                  <Tooltip id="my-tooltip" className="z-10" />
                </div>
              )}
            </>
          ) : (
            <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 text-white">
              {t('noUser')}
            </div>
          )}
        </>
      )}
    </>
  );
}

export async function getStaticProps({ locale }: { locale: string }) {
  return {
    props: {
      ...(await serverSideTranslations(locale, ['common'], null, [
        'us',
        'jp',
        'kr',
      ])),
    },
  };
}
